import controller from '@/controller'
import { isBuffActive } from './buffUtils'
import { COIN_ICON } from '@/utils'
import { ANTI_SPAM } from '../modules/buffs'

export default function getMsgPrice() {
  return 0
  // const pricesRate = controller.getState('auth.pricesRate')
  // return Math.floor(getMsgBasePrice() * pricesRate)
}

export function getCommentPrice() {
  const pricesRate = controller.getState('auth.pricesRate')
  const settings = controller.getState('intl.settings')
  const price = settings.commentCost 

  return Math.floor(price * pricesRate)
}

export function getCommentPriceTxt() {
  return COIN_ICON + getCommentPrice()
}

//возвращает базовую cтоимоcть без наценки
function getMsgBasePrice() {
  const myUid = controller.getState('auth.uid')
  const settings = controller.getState('intl.settings')
  if (!settings) { return 0 }

  //если висит антиспам баф
  if (isBuffActive(myUid, ANTI_SPAM)) {
    const panels = controller.getState('app.panels')
    if (panels.length > 0 && panels[panels.length - 1].type === 'dialog') {
      return settings.chatPrivateMinCost//cost for dialog messages
    }

    return settings.chatRoomMsgCost//cost for room messages
  }
  
  return 0
}
