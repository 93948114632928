import { callSequence, setStoreWrapper } from '../StoreManager'
import { getPostAuthor } from '../postUtils'
import { checkMySubscription } from '../subscriptionUtil'

const LOAD_MORE_GAP = 5

export function scrollToNextPost(postId){
  const currentElem = document.getElementById('profilePost' + postId)
  if (!currentElem || !currentElem.parentNode || !currentElem.parentNode.children){
    return
  }
  const children = currentElem.parentNode.children
  let element
  for (let i = 0; i < children.length; i++) {
    element = children[i]
    if (element === currentElem){
      const nextElement = children[i + 1]
      if (nextElement){
        if (nextElement.id === 'profilePostundefined'){
          setStoreWrapper('currentPostId', -1)
          setTimeout(()=>{
            nextElement.scrollIntoView({ behavior:'smooth' })
          }, 250)
          return
        }
        const postId = elemIdToPostId(nextElement.id)
        if (!checkMySubscription(getPostAuthor(postId))){
          setTimeout(()=>{
            nextElement.scrollIntoView({ behavior:'smooth' })
          }, 250)
          //если осталось не отсмотренными 3 профиля, то грузим следующую страницу
          if (i + LOAD_MORE_GAP > children.length){
            callSequence('posts.getMorePosts')
          }
          setStoreWrapper('currentPostId', postId)
          // setStoreWrapper('scrollNext', null)
          return
        }
      }
    }
  }
  // setStoreWrapper('scrollNext', null)
}

export function scrollToPost(postId){
  const currentElem = document.getElementById('profilePost' + postId)
  if (currentElem){
    currentElem.scrollIntoView()
  }
}

function elemIdToPostId(elemId){
 return  Number(elemId.replace('profilePost', ''))
}
