import { sequences, state } from 'cerebral'
import { checkMySubscription, getSubTypeColorCustom, isSubscribeAvailable } from '../../utils/subscriptionUtil'
import { loginPanel, subscriptionPanel } from '../Panels/types'
import { array, bool, func, object, string, number } from 'prop-types'
import { connect } from '@cerebral/react'
import React, { Component } from 'react'
import { isMobileSkin } from '../../utils'
import { subscriptionButtonClick } from '../../utils/subscriptionButtonClick'
import './buttons.css'
import { getProfilePostId } from '../../utils/userUtil'
import { getMyUid, imVIP } from '../../utils/my'
import { datingNeedProfile } from '../../modules/posts/signals/postLike'
import './likeAnimation.css'
// import { getStorage } from 'vconsole/src/lib/tool'
import { getState, setStoreWrapper } from '../../utils/StoreManager'
import is from 'is_js'
import { cancelDislike } from '../../utils/post/dislikePost'
import { myAnimateToggle } from '../Levels/animatedLike'
import LevelShape from '../Levels/LevelShape'
import AnimatedShape from '../Levels/AnimatedShape'
import AnimatedField from '../Levels/AnimatedField'
import { likeButtonClass } from '../../utils/buttonStyleUtil'
import { scrollToNextPost } from '../../utils/navigation/datingScrollUtil'

export function getLikeID(id){
  return 'like' + id
}


class SubscriptionButton extends Component {
  state = {
    click:false
  }

  // interval = -1
  // startAnimate(){
  //   const ha = this.state.heartsAnimated || []
  //   ha.push(
  //     <AnimatedShape size={28}/>
  //   )
  //   this.setState({ heartsAnimated: ha })
  // }

  animation(toggle){
    if (this.props.flyoutAnimation){
      myAnimateToggle(toggle)
    }
  }

  render() {
    const {
      uid,
      intl,
      isLoggedIn,
      createPanel,
      compactEnable,
      alwaysGray,
      postId,
      style,
      iconMode,
      posterMode,
      onGray,
      barButton,
      myLikes,
      forDislikeCancel,
      flyoutAnimation
    } = this.props

    const subType = (postId && !forDislikeCancel) ? myLikes.indexOf(postId) === -1 : checkMySubscription(uid)

    let buttonClass
    if (iconMode) {
      // buttonClass = 'buttonIcon'
    } else {
      if (onGray) {
        buttonClass = (subType || alwaysGray) ? 'simpleButton buttonOnGray' : 'simpleButton buttonBlue'
      } else {
        buttonClass = (subType || alwaysGray) ? 'simpleButton' : 'simpleButton buttonBlue'
      }
    }

    if (barButton) {
      buttonClass += ' mobileBar'
    }
    if (posterMode) {
      buttonClass = likeButtonClass(subType)
    }
    let iconClass = subType ? 'icon-flirty-like-full buttonIcon' : 'icon-flirty-like buttonIcon'

    //posterMode &&
    if (subType) {
      iconClass += ' filledLikeIcon'
    }
    let iconStyle
    let animate = false
    if (!posterMode) {
      iconStyle = { color: (!subType && alwaysGray) ? '#212121' : getSubTypeColorCustom(subType, iconMode ? '#000000' : '#FFF') }
    } else {
      animate = getState(getLikeID(uid)) && this.state.click

      if (this.prevAnimate !== animate && animate){
        setTimeout(() =>{
          this.setState({ click:false })
          setStoreWrapper(getLikeID(uid), false)
        }, 800)
      }

      this.prevAnimate = animate
    }

    return (
      <button className={buttonClass} id={flyoutAnimation ? 'flyoutAnimationButton' : ('subBtn' + uid)} style={style}
              onMouseDown={() =>{
                this.animation(true)
              }}
              onTouchStart={() =>{
                this.animation(true)
              }}
              onTouchEnd={()=>{
                this.animation(false)
              }}
              onMouseUp={()=>{
                this.animation(false)
              }}
              onMouseOut={()=>{this.animation(false)}}
              onClick={() => {
                // //если у меня нет профиля то лайкать нельзя
                // if (posterMode && !getProfilePostId(getMyUid()) && !imVIP()) {
                //   return datingNeedProfile()
                // }
                if (!isSubscribeAvailable(true)){
                  return
                }
                this.setState({ click:true })
                subscriptionButtonClick(uid, flyoutAnimation)
                cancelDislike(postId)
                if (this.props.autoScroll && !subType){
                  scrollToNextPost(postId)
                }
              }}>
        {!animate &&
          <i className={iconClass}
             style={iconStyle}/>
        }
        {(barButton || (!(isMobileSkin() && compactEnable) && !iconMode)) &&
          <span>{subType ? intl.subscribed : intl.subscribe}</span>
        }
        {animate &&
          <div className='like like-animation' style={{ marginTop: is.ios() ? '-2px' : '0px' }} id={getLikeID(uid)}/>
        }
        {
          flyoutAnimation && <AnimatedField/>
        }
      </button>
    )
  }
}

SubscriptionButton.propTypes = {
  uid: string,
  iconMode:bool,
  onGray:bool,
  barButton:bool,
  style: object,
  compactEnable:bool,
  alwaysGray:bool,
  intl: object,
  subs:object,
  createPanel: func,
  isLoggedIn: bool,
  posterMode:bool,
  likeClick:bool,
  postId:number,
  myLikes: array,
  forDislikeCancel: bool,
  autoScroll:bool,
  flyoutAnimation:bool
}
export default connect({
    intl: state`intl.content`,
    subs: state`auth.subs`,
    createPanel: sequences`app.createPanel`,
    isLoggedIn: state`auth.isLoggedIn`,
    myLikes: state`posts.myLikes`,
  },
  SubscriptionButton
)
