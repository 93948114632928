import { checkOnline, isStreaming } from '../modules/socket/actions'
import { PROMOTION } from '../modules/buffs'
import { isBuffActive } from './buffUtils'
import { getMyUid } from './my'

export function isPopularUser(uid){
 if (isStreaming(getMyUid(), true)){
  return false
 }
 return isStreaming(uid, true) || isBuffActive(uid, PROMOTION)
}
