import { chatNotification } from '../../modules/auth/actions/notificationUtil'
import { MESSAGE_TYPE_ASK, MESSAGE_TYPE_SYSTEM } from '../manyConst'
import { getLocaleValue } from '../localeUil'
import { getRoomOwnerUid } from '../navigation/navigationUtil'
import { getMyUid } from '../my'
import { getUserName } from '../userUtil'
import { sendAmplitudeEvent } from '../GAEvent'
import { getState, setStoreWrapper } from '../StoreManager'
import { saveMetadata } from '../../modules/room/signals/sendCreateBroadcast'
import getStreamMetadata from '../getStreamMetadata'
import { isPrivateAvailable } from '../directCallThemeUtil'

const ASK_GIFT  = 'askGift'
const ASK_CALL  = 'askCall'
const ASK_HERO  = 'askHero'

const FROM_AUTHOR_TYPES = [
  {
    type: ASK_GIFT,
    count: 10,
    key: 'call_to_gift'
  },
  {
    type: ASK_CALL,
    count: 10,
    key: 'call_to_action'
  },
  {
    type: ASK_HERO,
    count: 5,
    key: 'call_for_character'
  }
]

let timeout
let lastUid
const WATCH_DELAY = 20000 //20 секунд
const WATCH_DELAY_DELTA = 10000 //10 секунд - доп рандом, что бы было похоже на ручную отправку

//попрашайки привязаны к длительности просмотра
export function startWatch(uid){
  if (uid === lastUid || isAutoAskDisable(uid)){
    return
  }

  if (lastUid !== uid && timeout){
    clearTimeout(timeout)
  }
  lastUid = uid

  timeout = setTimeout(checkAuthorAskNotification, WATCH_DELAY + WATCH_DELAY_DELTA*Math.random())
}

export function endWatch(){
  if (timeout){
    clearTimeout(timeout)
  }
  lastUid = undefined
}

//прошел таймер берем случайное предложение
export function checkAuthorAskNotification(){
  const from = getRoomOwnerUid()
  if (isAutoAskDisable(from)){
    return
  }
  const ask = FROM_AUTHOR_TYPES[Math.floor(Math.random() * FROM_AUTHOR_TYPES.length)]

  //доп условие, если нет приватов, то их и не просит
  if (ask.type === ASK_CALL && !isPrivateAvailable(from)){
    return
  }

  const key = ask.key + '_' + Math.floor(Math.random() * ask.count)
  const text = getLocaleValue(key)

  const to = getMyUid()

  sendAmplitudeEvent('author_ask', { key })
  chatNotification(
    key,
    { askType: ask.type },
    MESSAGE_TYPE_ASK,
    false,
    {
      text: text,
      messageWeb:text,
      messageApp:text,
      messageOriginal: text,
      from: from,
      to: to
    }
  )
}

export function disableAutoAsk(disable){
  let metadata = getState('auth.storage.metadata') || {}
  metadata.disableAutoAsk = disable
  setStoreWrapper('auth.storage.metadata', metadata)
  saveMetadata()
}

export function isAutoAskDisable(uid){
    return (getStreamMetadata(uid || getMyUid())  || {}).disableAutoAsk
}
