import React, { Component } from 'react'
import LevelShape from './LevelShape'
import { getMyUid } from '../../utils/my'
import { number, string, object } from 'prop-types'
import { state } from 'cerebral'
import { getLevel } from '../../utils/levelUtil'
import ImageWithLoader from '../ImageWithLoader'
import { getBaseImageUrl, getImageUrl } from '../../utils/urlUtils'

class AnimatedShape extends Component {

  state = {
    visible: true
  }

  animateInterval
  // direction = 1
  // frames = 0
  componentDidMount() {
    setTimeout(()=>{
      this.setState({ visible: false })
    }, this.props.duration)
    // this.animateInterval = setInterval(()=>{
    //   let frames = this.state.frames + 1
    //   let directionLeft = this.state.directionLeft
    //   if (frames > Math.max(30,Math.random()*50)){
    //     directionLeft = !this.state.directionLeft
    //     frames = 0
    //   }
    //   const stepSwing = 0.5//this.props.size*2
    //
    //   // const maxSwing = this.props.size*3
    //   // let swing = stepSwing * Math.random()
    //   let x = this.state.x + (directionLeft ? stepSwing : -stepSwing)
    //
    //   let y = this.state.y + 1.5//this.props.size
    //   this.setState({ x, y, frames, directionLeft })
    // }, 5)
  }

  componentWillUnmount() {

  }

  getShape(){
    const {
      size,
      uid,
      giftId
    } = this.props

    const swingType = 'swing'+ Math.ceil(Math.random()*3) + ' ' + Math.max(Math.random()*1, 0.3) + 's alternate infinite ease-in-out'
    const lvl = getLevel(uid)

    if (giftId){
      return ( <div style={{ height: 'auto', position:'relative', animation: 'swing3 1s alternate infinite ease-in-out' }}>
        <ImageWithLoader style={{ width: 100, height: 160 }} src={getBaseImageUrl('/presents/balloons_gift.webp')} />
        <ImageWithLoader style={{ width: 100, height: 100, marginTop:-30 }} src={getBaseImageUrl(`/presents/big/l/${giftId}.png`)} />
      </div>)
    }
    if(lvl > 0){
      return <LevelShape uid={uid} size={size} noStars={true} noVerify={true} style={{ animation: swingType }}/>
    }

    return(<div style={{ animation: swingType, minWidth:size, minHeight:size, fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignContent:'center',
        alignItems: 'center',
        lineHeight: '1em',
        fontStretch: 'normal',
        fontStyle: 'normal',
        // backgroundColor: '#f6f6f6'
      }}>
        {/*<i className={'icon-flirty-like-full buttonIcon filledLikeIcon'} />*/}
      <img src={`${getImageUrl()}/levels_new/snowflake@big.webp`} style={{
        width: size + 'px',
        height: size + 'px'
      }}/>
      </div>)
  }

  render() {
    if (!this.state.visible){
      return null
    }

    const animate = this.props.giftId ? ' flyUpOnGift 10s' : 'flyUpOnLike 3s'
    return(
      <div style={{ position: 'absolute', animation: animate, transform: 'translate(-50%, -50%)' }}>
        {this.getShape()}
      </div>
    )
  }
}

AnimatedShape.propTypes = {
  size: number,
  uid: string,
  giftId:string,
  duration: number
}

export default AnimatedShape
