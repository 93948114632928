import { CHARACTER_AWARD, getAwardConfig, TEST_AWARD } from '../awards/awardsConfig'
import { getSettings } from '../settingsUtil'
import { getState, pushStoreWrapper, setStoreWrapper, unshiftStoreWrapper } from '../StoreManager'
import { getMyUid } from '../my'
import { getUserMainCharacter } from '../awards/getUserMainCharacter'
import { getUser, loadUsers } from '../userLoader'
import { getCurrentRoomId } from '../navigation/navigationUtil'
import { ANIMATE_MASS_HIT, gameFinnish } from './cardGameTick'
import { clearCardGame } from './cardGameAPI'
import { wait } from '../wait'
import { isMe } from '../checks'
import { sendAmplitudeEvent } from '../GAEvent'
import { imAdmin } from '../adminUtil'
import { getBuff, getBuffCost, isBuffActive } from '../buffUtils'
import {
  CHICKEN,
  CHICKEN_LONG,
  CLEAR_ALL_NEGATIVE_BUFF,
  HP_INCREASE, RABBIT,
  SHIELD,
  STRENGTH_INCREASE
} from '../../modules/buffs'
import { SPAMMER_UID } from '../../modules/chat/actions'

export const GAME_CARD_HERO = 'hero'
export const GAME_CARD_MAGIC = 'magic'

export const GAME_CARD_BOSS_HEAL = 'boss_heal'
export const GAME_CARD_ATTACK = 'attack'
export const GAME_CARD_CARD_BUFFS = 'cards_buff'

export const GAME_CARD_BUFFS = 'buffs'
export const GAME_CARD_BOSS_ATTACK = 'boss_attack'
export const GAME_CARD_BOSS_ATTACK_DEBUFF = 'boss_attack_debuff'
export const GAME_CARD_ROOM_OWNER_ACTION = 'room_owner'

export const GAME_REWARD_EXP = 'exp'
export const GAME_REWARD_COINS = 'coins'

export const GS_IN_PROGRESS = 'in_progress'
export const HERO_WON = 'hero_won'
export const EXP_INCREASE = 'exp_increase' // увеличние опыта


export const CLEAR_DEBUFF_CARD = 'clear_debuffs'
export const MASS_HIT = 'mass_hit'
export const CLEAR_BONUS_HP = 'bonus_hp'
export const CLEAR_ALL_NEGATIVE_BUFF_CARD = 'clear_debuff'

export const CHICKEN_HERO = 'chicken'
export const RABBIT_HERO = 'rabbit'
export const CHICKEN_HERO_LONG = 'long_chicken'
export const LAMB_DEBUFF = 'lamb'
export const PUMPKIN_DEBUFF = 'mass_pumpkin'

export const BOSS_HEAL_SMALL = 'heal_boss_small'
export const BOSS_HEAL_BIG = 'heal_boss_big'

export const ENERGY = 'energy'

export const DEFAULT_BOSS = 'dragon_1'
// export const DEFAULT_BOSS = 'halloween'

export function getStrength(cardId){
  const award = getAwardConfig(cardId)

  //если это не карточка, проверим на магию
  if (!award){
    const magic = getMagic(cardId)
    if (!magic && award.category !== CHARACTER_AWARD){
      return 1
    }
    return magic.strength
  }

  const gameHeroes = getGameHeroesConfig()
  if (!award.hero_class && award.category === CHARACTER_AWARD){
    if (gameHeroes.default){
      return gameHeroes.default.strength
    }
  }

  const heroClass = gameHeroes[award.hero_class]
  if (heroClass){
    return heroClass.strength
  }

  return 0
}

export function getHP(cardId){
  const award = getAwardConfig(cardId)

  //если это не карточка, проверим на магию
  if (!award || award.category !== CHARACTER_AWARD){
    return 0
  }

  const gameHeroes = getGameHeroesConfig()
  if (!award.hero_class){
    if ((gameHeroes.default && gameHeroes.default.hp) || award.category === CHARACTER_AWARD){
      return gameHeroes.default.hp
    }
  }

  const heroClass = gameHeroes[award.hero_class]
  if (heroClass){
    return heroClass.hp || gameHeroes.default.hp
  }

  return 0
}

export function prepareGameField(cards){

}

export function getGameHeroesConfig() {
  return getSettings('card_game_heroes') || {}
}

export function getMagicConfig() {
  return getSettings('card_game_magic') || {}
}

export function getMagic(cardId){
  return getMagicConfig()[cardId] || {}
}

//добавление пачкой
export function cardGameNewCards(cards, byPriority){
  prepareCards(cards, (prepared)=>{
    const gf = getState('gameField') || []
    prepared.forEach(gameCard => {
      if (byPriority && gameCard.card_type === GAME_CARD_MAGIC){
        const magic = getMagic(gameCard.card_id)
        if (magic.priority < 0){
          gf.unshift({ ...gameCard, date: Date.now() })
          // unshiftStoreWrapper('gameField', { uid: gameCard.uid, card_id: gameCard.card_id, card_type: gameCard.card_type, strength: gameCard.strength, date: Date.now() })
          return
        }
      }
      // count++
      // setTimeout(()=>{

      gf.push({ ...gameCard, date: Date.now() })

        // addGameCard(gameCard.card_id, gameCard.uid, gameCard.card_type, gameCard.strength)
      // }, 10)
    })
    // if (!byPriority) {
      setStoreWrapper('gameField', gf)
    // }
  })
}

export function addGameCard(gameCard){
  cardGameNewCards([gameCard], true)
  // prepareCards([gameCard], (prepared)=>{
  //   //пришел массив карточек, достанем первую
  //   const preparedCard = prepared[0]
  //   if (preparedCard.card_type === GAME_CARD_MAGIC){
  //     const magic = getMagic(preparedCard.card_id)
  //     if (magic.priority < 0){
  //       // addGameCard(gameCard.card_id, gameCard.uid, gameCard.card_type, gameCard.strength)
  //       // setStoreWrapper('uk', { uid: uid, card_id: cardId, card_type: type, strength: strength, date: Date.now() } )
  //       unshiftStoreWrapper('gameField', { uid: preparedCard.uid, card_id: preparedCard.card_id, card_type: preparedCard.card_type, strength: preparedCard.strength, date: Date.now() })
  //     } else {
  //       pushStoreWrapper('gameField', { uid: preparedCard.uid, card_id: preparedCard.card_id, card_type: preparedCard.card_type, strength: preparedCard.strength, date: Date.now() })
  //       // pushStoreWrapper('gameField', { uid: uid, card_id: cardId, card_type: type, strength: strength, date: Date.now() })
  //     }
  //   } else {
  //     // setTimeout(()=> {
  //     pushStoreWrapper('gameField', { uid: preparedCard.uid, card_id: preparedCard.card_id, card_type: preparedCard.card_type, strength: preparedCard.strength, date: Date.now() })
  //     // }, 10)
  //   }
  // })
}

const DEFAULT_HERO = 'default'
const DEFAULT_VIP_HERO = 'vip'

export function getRealCard(cardId, uid){
  if (cardId === DEFAULT_HERO || cardId === DEFAULT_VIP_HERO){
    return getUserMainCharacter(uid)
  }
  return cardId
}

export function prepareAndSetGameField(cards){
  if (!cards.length){
    setStoreWrapper('gameField',  [])
  }
  prepareCards(cards, (preparedCards)=>{
    setStoreWrapper('gameField',  preparedCards)
  })
}

export function prepareCards(cards, cb){
  const newUsers = []
  cards.forEach(gameCard => {
    newUsers.push(gameCard.uid)
  })

  if (newUsers.length){
    loadUsers(newUsers, ()=>{
      cards.forEach(gameCard => {
        if (gameCard.card_id === DEFAULT_HERO || gameCard.card_id === DEFAULT_VIP_HERO){
          gameCard.card_id = getUserMainCharacter(gameCard.uid)
        }
      })

      //уберем анимацию босса
      setStoreWrapper('animateBoss', null)
      cb(cards)
    })
  }
}

function prepareGameBuffCards(isMe, type){
  const gameBuffs = getSettings('game_buffs') || {}
  const buffs = []
  Object.keys(gameBuffs).map(buffId => {
    const buff = gameBuffs[buffId]
    if (!getBuff(buff.buff_id) || (buff.visible === TEST_AWARD && !imAdmin())){
      return
    }
    //bad: isChickenCard(buffId),
    buffs.push({ ...buff, card_id:buffId, cost: getBuffCost(buff.buff_id) })
  })

  //если это для меня, то сначала будем показывать хорошее, потом плохое, если не для меня то наоборот
  buffs.sort((a,b) => {
    const buffA = getBuff(a.buff_id)
    const buffB = getBuff(b.buff_id)
    if (!buffA && buffB){
      return 1
    }

    if (buffA && !buffB){
      return -1
    }

    if (!buffA && !buffB){
      return 0
    }

    if (isMe){
      if (buffA.bad && !buffB.bad){
        return 1
      }
      if (!buffA.bad && buffB.bad){
        return -1
      }
      return 0
    } else {
      if (!buffA.bad && buffB.bad){
        return 1
      }
      if (buffA.bad && !buffB.bad){
        return -1
      }
      return 0
    }
  })
  if (type === ENERGY){
    return buffs.filter(item => item.type === ENERGY)
  }
  return buffs
}

export function getMagicCardsByType(type, uid){
  if (type === GAME_CARD_BUFFS || type === ENERGY){
    return prepareGameBuffCards(isMe(uid), type)
  }

  const magicCards = getMagicConfig()
  const cards = []
  Object.keys(magicCards).map(cardId => {
    const card = magicCards[cardId]
    if (card.visible === TEST_AWARD && !imAdmin()){
      return
    }
    // атака дефолтный тип, поэтому он не прописан
    if (type === GAME_CARD_ATTACK){
      if (!card.type){
        cards.push({ ...card, card_id:cardId })
      }
    } else if (card.type === type){
      cards.push({ ...card, card_id:cardId })
    }
  })

  return cards
}

export function cardGameWinner(data){
  if (isMe(data.winner)){
    sendAmplitudeEvent('card_game', {
      type: 'winner',
      reward: data.reward.reward_type,
      value: data.reward.reward_value
    })
  }
  if (data.room_id === getCurrentRoomId()) {
    setHerroWonState(data)
  }
}

export function setHerroWonState(data){
  setStoreWrapper('animateBoss', null)
  setStoreWrapper('winnerInfo', data)
}

export function getStrengthWithBuffs(cardGame){
  if (isChickenCard(cardGame.card_id)){
    return getStrength(CHICKEN_HERO)
  }
  //на петушка дебаф не работает
  if (cardGame.debuff){
    const mc = getMagic(cardGame.debuff)
    return mc.strength
  }
  return cardGame.strength
}

export function isBossAttack(cardId){
  const mc = getMagic(cardId)
  return mc && mc.type === GAME_CARD_BOSS_ATTACK
}

export function isBossDeBuff(cardId){
  return isDebuffCard() && cardId !== ANIMATE_MASS_HIT
}

export function isChicken(uid){
  return isBuffActive(uid, CHICKEN) || isBuffActive(uid, CHICKEN_LONG)
}

export function isRabbit(uid){
  return isBuffActive(uid, RABBIT)
}

export function isConverted(uid){
  return isRabbit(uid) || isChicken(uid)
}

export function isBuffShield(uid){
  return isBuffActive(uid, CLEAR_ALL_NEGATIVE_BUFF) || isBuffActive(uid, SHIELD)
}

export function anyDebuffInGameField(){
  const gameField = getState('gameField')
  if (!gameField){
    return null
  }
  return gameField.find(
    card => card.debuff
  )
}

export function getGameCardFromBuff(buffToAward){
  const gameBuffs = getSettings('game_buffs') || {}
  let awardId
  Object.keys(gameBuffs).map(buffId => {
    const buff = gameBuffs[buffId]
    if (buff.visible === TEST_AWARD && !imAdmin()){
      return
    }
    if (buff.buff_id == buffToAward){
      awardId = buffId
    }
  })
  return awardId
}

export function getGameCard(awardId){
  const gameBuffs = getSettings('game_buffs') || {}
  return gameBuffs[awardId]
}

export function isStrengthIncrease(uid){
  // return true
  return isBuffActive(uid, STRENGTH_INCREASE)
}

export function isHPIncrease(uid){
  if (isMe(uid)){
    console.log('some')
  }
  return isBuffActive(uid, HP_INCREASE)
}

export function isChickenCard(awardId){
  return awardId === CHICKEN_HERO || awardId === CHICKEN_HERO_LONG
}

export function isRabbitCard(awardId){
  return awardId === RABBIT_HERO
}

export function getPriority(cardId) {
  const award = getMagic(cardId)
  return award && award.priority
}

export function isBoss(uid){
  return uid === SPAMMER_UID
}

export function isDebuffCard(cardId){
  return cardId === LAMB_DEBUFF || cardId === PUMPKIN_DEBUFF
}