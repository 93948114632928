import controller from '@/controller'

//возвращает локализацию ключа, с заменой параметров или сам ключ
export function getLocaleValue(key, replaceParams = {}, noKey) {
  try{
    if (!controller.getState('intl')){
      if (noKey){
        return null
      }
      return key
    }
    const locale = controller.getState('intl.content') || {}
    const value = locale[key]
    if (!value){
      if (noKey){
        return null
      }
      return key
    }

    let final = value
    Object.keys(replaceParams).map(key => {
      final = final.replace(key, replaceParams[key])
    })

    return final
  }catch (e){
    if (noKey){
      return null
    }
    return key
  }
}

//приводит первую букву в заглавную
export const firstCharToUpper = str => {
  if (!str){
    return str
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

