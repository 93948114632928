import { getState } from './StoreManager'
import { getPostNew } from '../modules/posts/signals/getPost'
import { getBaseImageUrl, getImageUrl } from './urlUtils'
import { getSettings } from './settingsUtil'

export function getGiftStars(giftId) {
  return getInfo(giftId).stars || 5
}

export function getGiftCost(giftId) {
  return getInfo(giftId).cost || 0
}

export function getInfo(giftId) {
  // if (giftId === 'test'){
  //   return {
  //     cost: 300,
  //     newFormat:true,
  //     fullSizeVideo:1783777,//1783778
  //     videoPost: 1780296,//1780295
  //   }
  // }
  const extraStarsGifts =  getState('intl.settings.giftsNew') || {}
  if (!extraStarsGifts[giftId]){
    return {}
  }
  return extraStarsGifts[giftId] || {}
}

export function getVideoGiftPost(giftId, withLoad, fullSize){
  const postId = fullSize ? getInfo(giftId).fullSizeVideo : getInfo(giftId).videoPost
  if (postId && withLoad){
    getPostNew(postId, true, ()=>{})
  }
  return postId
}

export function isVideoGift(giftId){
  return getVideoGiftPost(giftId)
}

export function isNewFormatSet(setId){
  const setInfo = (getSettings('giftsSets') || {})[setId]
  return setInfo && setInfo.newFormat
}

export function isNewFormatGift(giftId){
  return getInfo(giftId).newFormat
}

export function getSetCover(setId){
  return isNewFormatSet(setId) ? getBaseImageUrl('/presents/set/en/l/' + setId + '_cover.webp') : getImageUrl() + `/presents/set/${getState('intl.lang')}/l/` + setId + '_cover.jpg'
}

export function getGiftImg(giftId){
  return isNewFormatGift(giftId) ? getBaseImageUrl('/presents/big/l/' + giftId + '.webp')  : getImageUrl() + '/presents/big/l/' + giftId + '.png'
}
