
export const MESSAGE_TYPE_UPLOAD = 'mediaFileUpload'
export const MESSAGE_TYPE_MEDIA = 'media'
export const MESSAGE_TYPE_AWARD = 'award'
export const MESSAGE_TYPE_AUTO = 'auto'
export const MESSAGE_TYPE_STICKER = 'sticker'
export const MESSAGE_TYPE_BUFF = 'buff'
export const MESSAGE_TYPE_SYSTEM = 'system'
export const MESSAGE_TYPE_ASK = 'ask'
export const MESSAGE_TYPE_GIFT = 'gift'


