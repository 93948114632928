import config from '@/config'
const { ftCoreUrl, geoURL, ftAward, minigamesUrl, minigamesUrlDev, vbApiUrl } = config

export function getCoreUrl(url) {
  return ftCoreUrl + url
}

export function getUsersURL(url) {
  return getCoreUrl('/api/v1/users/' + url)
}

export function getGEOUrl(url) {
  return geoURL + url
}

export function getReviewsURL(url) {
  if (!url){
    return getCoreUrl('/api/v1/reviews')
  }
  return getCoreUrl('/api/v1/reviews/' + url)
}

export function getAwardURL(url) {
  return ftAward + url
}

export function getMiniGamesUrl(url){
  if (!isProdGame){
    return minigamesUrlDev + url
  }
 return minigamesUrl + url
}

export function wowzaApiURl(url){
  return vbApiUrl + url
}
