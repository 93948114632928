import { isMe, universalWssSend } from '../../utils'
import { getMyUid } from '../../utils/my'
import { getState, pushStoreWrapper, shiftStoreWrapper } from '../../utils/StoreManager'
import { imInRoom } from '../../utils/appUtil'
import { checkCurrentRoomId, getCurrentRoomId } from '../../utils/navigation/navigationUtil'
import { isVideoGift } from '../../utils/giftUtil'
import { addOverlayVideoGift } from '../../utils/room/overlayVideoGift'
// import LevelShape from './LevelShape'
// import { heartsAnimated } from '../Button/SubscriptionButton'
// import React from 'react'

/**
 * durations - массив из нажатиу и пауз
 * @param fromId
 * @param roomId
 * @param durations
 */
export function animatedLikeReceived(fromId, roomId, durations, start){
  //првоерка нужна, что бы циклы анимации закончились при выходе из комнаты
  if (isMe(fromId) || !checkCurrentRoomId(roomId)){
    return
  }
  if (!durations || !durations.length){
    return
  }

  //достанем первый элемент, если это старт,
  // то начнем анимацию по длительности,
  // если это пауза, то ничего не делаем и перезапускаем после таймаута
  const time = Math.min(durations.pop(), MAX_DURATION)
  if (time){
    let interval = -1
    if (start){
      startAnimate(fromId)
      interval = setInterval(()=>{
        if (!checkCurrentRoomId(roomId)){
          if (interval !== -1){
            clearInterval(interval)
          }
          return
        }
        startAnimate(fromId)
      }, NEW_LIKE_DELAY)
    }
    setTimeout(()=>{
      if (interval !== -1){
        clearInterval(interval)
      }
      animatedLikeReceived(fromId, roomId, durations, !start)
    }, time)
  }
}

let myInterval = -1
const SEND_TIMEOUT = 1000
const NEW_LIKE_DELAY = 150
export const FLY_GIFT_DURATION = 10000
const MAX_DURATION = 20000

let sendTimeout = -1
let time
let myIntervals = []
export function myAnimateToggle(start, singleTap){
  if(start){
    //если начало нажатия, очистим интервал отправки и прошлый интервал если он есть
    if (myInterval !== -1){
      clearInterval(myInterval)
    }
    if (sendTimeout !== -1){
      clearTimeout(sendTimeout)
    }
    // if (singleTap){
    //   startAnimate(getMyUid())
    //   myIntervals = [NEW_LIKE_DELAY]
    //   sendLikeIntervals()
    //   return
    // }
    //при 10 отправляем форсировано
    if (myIntervals.length > 10){
      sendLikeIntervals()
    }
    if (time){
      myIntervals.push(Math.min(Date.now() - time, MAX_DURATION))
    }

    time = Date.now()

    startAnimate(getMyUid())
    //начнем интервал анимации
    myInterval = setInterval(()=>{
      startAnimate(getMyUid())
    }, NEW_LIKE_DELAY)
  } else {
    if (myInterval !== -1){
      if (sendTimeout !== -1){
        clearTimeout(sendTimeout)
      }
      myIntervals.push(Date.now() - time)
      time = Date.now()
      sendTimeout = setTimeout(()=>{
        sendLikeIntervals()
      }, SEND_TIMEOUT)
      clearInterval(myInterval)
    }
  }
}

function sendLikeIntervals (){
  time = null
  universalWssSend('animatedLike', { fromId: getMyUid(), durations:myIntervals })
  myIntervals = []
}

function startAnimate(fromId){
  //найдем элемент сердечка из котрого будут вылетания
  pushStoreWrapper('app.animatedLikes', { uid:fromId, date: Date.now() })

  //здесь задержка на время анимации
  setTimeout(()=>{
    shiftStoreWrapper('app.animatedLikes')
  }, 3000)
}

export function startAnimateGift(fromId, giftId){
  if (isVideoGift(giftId)){
    addOverlayVideoGift(giftId)
    return
  }
  //найдем элемент сердечка из котрого будут вылетания
  pushStoreWrapper('app.animatedLikes', { uid:fromId, giftId:giftId, date: Date.now() })

  //здесь задержка на время анимации
  setTimeout(()=>{
    shiftStoreWrapper('app.animatedLikes')
  }, FLY_GIFT_DURATION)
}
